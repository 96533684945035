import { getEngage } from './engage';

const isTrackingEnabled = () => {
  return window.cdpVariables?.trackingEnabled && window.cdpVariables?.cookieBotEnabled;
};

const identity = (identityObject) => {
  getEngage((engage) => {
    if (
      engage === null ||
      ((typeof identityObject.checkTrackingEnabled === 'undefined' || identityObject.checkTrackingEnabled) &&
        !isTrackingEnabled())
    ) {
      return;
    }

    const identifiers = [];
    if (identityObject.email) {
      identifiers.push({
        id: identityObject.email,
        provider: 'email',
      });
    }

    const eventData = {
      channel: 'WEB',
      currency: window._THULEDATA.currencyCode,
      pointOfSale: window.cdpVariables.pointOfSale,
      language: window.cdpVariables.language,
      country: window.cdpVariables.market,
      page: window.cdpVariables.Page.pageId,
      email: identityObject.email,
      firstName: identityObject.firstName,
      lastName: identityObject.lastName,
      mobile: identityObject.mobile,
      phone: identityObject.phone,
      street: identityObject.streets,
      city: identityObject.city,
      state: identityObject.state,
      postalCode: identityObject.postalCode,
      identifiers,
    };

    const extensionData = {
      addressCountry: identityObject.addressCountry,
      source: identityObject.source,
    };

    engage.identity(eventData, extensionData);
  });
};

const initEngage = () => {
    getEngage((engage) => {
        if (engage === null || !isTrackingEnabled()) {
            return;
        }
    });
};

const pageView = () => {
  getEngage((engage) => {
    if (engage === null || !isTrackingEnabled()) {
      return;
    }

    const eventData = {
      channel: 'WEB',
      language: window.cdpVariables.language.toUpperCase(),
      currency: window._THULEDATA.currencyCode,
      page: window.cdpVariables.Page.pageId,
    };

    const extensionData = {
      market: window.cdpVariables.market.toUpperCase(),
      pageType: window.cdpVariables.Page.pageType,
      pageTitle: window.cdpVariables.Page.pageTitle,
      pageParentCategory: window.cdpVariables.Page.pageCategory,
      pageTags: window.cdpVariables.Page.pageTags,
    };

    engage.pageView(eventData, extensionData);
  });
};

const addToCart = (
  productName,
  productId,
  productPriceNoCurrency,
  productType,
  currency,
  productpageUrl,
  productImageUrl,
  cartId,
  quantity,
  source,
  webTitle1,
  webTitle2
) => {
  getEngage((engage) => {
    if (engage === null || !isTrackingEnabled()) {
      return;
    }

    const eventData = {
      channel: 'WEB',
      currency,
      pointOfSale: window.cdpVariables.pointOfSale,
      language: window.cdpVariables.language,
      product: {
        name: productName,
        type: productType,
        item_id: productId,
        productId,
        orderedAt: new Date().toISOString(),
        quantity,
        price: productPriceNoCurrency,
        currency,
      },
    };

    const extensionData = {
      productpageUrl,
      imageUrl: productImageUrl,
      cartId,
      source,
      webTitle1,
      webTitle2
    };

    engage.event('ADD', eventData, extensionData);
  });
};

const addToCartByCartLine = (cart, productId, source) => {
  getEngage((engage) => {
    if (engage === null || !isTrackingEnabled()) {
      return;
    }

    let currentCartLine = null;
    for (let i = 0; i < cart.Lines.length; i += 1) {
      const cartLine = cart.Lines[i];
      if (cartLine.ProductId === productId) {
        currentCartLine = cartLine;
        break;
      }
    }

    let productName = currentCartLine?.Title;
    if (currentCartLine?.Title2.length > 0) {
      productName = `${productName} ${currentCartLine.Title2}`;
    }

    addToCart(
      productName,
      productId,
      currentCartLine?.LinePriceNoCurrencyCode,
      currentCartLine?.Category,
      window._THULEDATA.currencyCode,
      currentCartLine?.ProductUrl,
      currentCartLine?.ProductImageTracking,
      cart.ExternalId,
      parseInt(currentCartLine?.Quantity, 10),
      source,
      currentCartLine?.Title,
      currentCartLine?.Title2
    );
  });
};

const confirm = (productIds) => {
  getEngage((engage) => {
    if (engage === null || !isTrackingEnabled()) {
      return;
    }

    const eventData = {
      channel: 'WEB',
      currency: window._THULEDATA.currencyCode,
      pointOfSale: window.cdpVariables.pointOfSale,
      language: window.cdpVariables.language,
      page: window.cdpVariables.Page.pageId,
      product: productIds,
    };

    engage.event('CONFIRM', eventData, null);
  });
};

const checkoutPurchased = (orderId) => {
  getEngage((engage) => {
    if (engage === null || !isTrackingEnabled()) {
      return;
    }

    const eventData = {
      channel: 'WEB',
      currency: window._THULEDATA.currencyCode,
      pointOfSale: window.cdpVariables.pointOfSale,
      language: window.cdpVariables.language,
      page: window.cdpVariables.Page.pageId,
      reference_id: orderId,
      status: 'PURCHASED',
    };

    engage.event('CHECKOUT', eventData, null);
  });
};

const subscriptions = (subscriptions) => {
  getEngage((engage) => {
    const eventData = {
      channel: 'WEB',
      pointOfSale: window.cdpVariables.pointOfSale,
      language: window.cdpVariables.language,
    };

    const extensionData = {
      subscriptions,
    };

    engage.event('SUBSCRIPTIONS', eventData, extensionData);
  });
};

const interests = (interests) => {
  getEngage((engage) => {
    if (engage === null) {
      return;
    }

    const eventData = {
      channel: 'WEB',
      pointOfSale: window.cdpVariables.pointOfSale,
      language: window.cdpVariables.language,
      country: window.cdpVariables.market,
      currency: window._THULEDATA.currencyCode,
      page: window.cdpVariables.Page.pageId,
    };

    const extensionData = {
      interests,
    };

    engage.event('INTERESTS', eventData, extensionData);
  });
};

const forgetMe = (email, customerServiceEmails) => {
  getEngage((engage) => {
    if (engage === null) {
      return;
    }
    const eventData = {
      channel: 'WEB',
      pointOfSale: window.cdpVariables.pointOfSale,
      language: window.cdpVariables.language,
      country: window.cdpVariables.market,
      currency: window._THULEDATA.currencyCode,
      page: window.cdpVariables.Page.pageId,
    };

    const extensionData = {
      email: email,
      customerServiceEmails: customerServiceEmails
    };

    engage.event('FORGETME', eventData, extensionData);
  });
};

const forceCloseSession = () => {
  getEngage((engage) => {
    if (engage === null) {
      return;
    }

    const eventData = {
      channel: 'WEB',
      browser_id: window.Engage.getBrowserId(),
      pointOfSale: window.cdpVariables.pointOfSale,
    };
    
    engage.event('FORCE_CLOSE', eventData);
  });
};

const getBase64EmailCustomField = (fieldValue) => {
    return { name: 'EmailBase64Encoded', value: btoa(fieldValue) }
}

const signupSources = Object.freeze({
  checkout: 'Checkout',
  myAccount: 'MyAccount',
  footer: 'Footer',
  preferenceCenter: 'Preference center',
  register: 'Register',
  popup: 'Popup',
  productRegistration: 'Product registration',
  standaloneNewsletter: 'Standalone newsletter',
});

const addToCartSources = Object.freeze({
  shoppingCart: 'Shopping Cart',
  sparePartsList: 'Spare Parts List',
  keysAndLocksList: 'Keys And Locks List',
  productDetailPage: 'Product Detail Page',
  bundleProductDetailPage: 'Bundle Product Detail Page',
  upsell: 'Upsell',
});

const identitySources = Object.freeze({
  login: 'Login',
  register: 'Register',
  productRegistration: 'Product Registration',
  checkout: 'Checkout',
  checkoutNewsletterSignup: 'Checkout Newsletter Signup',
  checkoutLogin: 'Checkout Login',
  checkoutPaymentNewsletterSignup: 'Checkout Payment Newsletter Signup',
  checkoutShipping: 'Checkout Shipping',
  checkoutKlarna: 'Checkout Klarna',
  myAccountShipping: 'My Account Shipping',
  footerNewsletterSignup: 'Footer Newsletter Signup',
  popupNewsletterSignup: 'Popup Newsletter Signup',
  preferenceCenter: 'Preference Center',
  notifyMe: 'Notify Me',
  interests: 'Interests',
  standaloneNewsletterSignup: 'Standalone Newsletter Signup',
});

export {
  identity,
  pageView,
  addToCartByCartLine,
  addToCart,
  confirm,
  checkoutPurchased,
  subscriptions,
  interests,
  signupSources,
  addToCartSources,
  identitySources,
  forgetMe,
  forceCloseSession,
  initEngage,
  getBase64EmailCustomField,
};
