import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner';
function Notification({
  Type,
  Size,
  Message,
  RenderExtraContent,
  ShowIcon,
  IsToast,
  SetMessage,
  HideCloseBtn,
  Disabled,
  CloseAction,
  Title,
}) {
  const [hideMessage, setHideMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (IsToast && !hideMessage) {
      setTimeout(() => {
        setHideMessage(true);
        if (SetMessage) SetMessage('');
        if (CloseAction) CloseAction();
      }, 5000);
    }
  }, []);

  const getIcon = () => {
    switch (Type) {
      case 'error':
        return 'minus-circle';
      case 'success':
        return 'check-circle';
      case 'warning':
        return 'alert-circle';
      case 'neutral':
        return 'info';
      case 'information':
        return 'info';
      default:
        return 'info';
    }
  };

  const closeNotification = async () => {
    if (CloseAction) {
      setIsLoading(true);
      await CloseAction();
    }

    setHideMessage(true);
  };

  return (
    <>
      {!hideMessage && (
        <div className={`notification__${IsToast ? 'toast' : 'inline'} ${Type} size--${Size}`}>
          <div className="d-flex">
            {ShowIcon && (
              <div className="icon">
                <i className="le-icon-bg"></i>
                <i className={`notification__iconType le-icon-${getIcon()}`}></i>
              </div>
            )}
            <div className={ShowIcon ? 'show-icon d-flex align flex-column justify-content-center' : ''}>
              {Title && <p className="heading5 notification__title mb-3">{Title}</p>}
              <p
                className={`${Size == 'sm' ? 'paragraph--xs' : 'paragraph--s'} notification__message mb-0 ${Disabled ? 'disabled' : ''
                  }`}
                dangerouslySetInnerHTML={{ __html: Message }}
              ></p>
              {RenderExtraContent && <RenderExtraContent />}
            </div>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {!HideCloseBtn && (
                <button className="btn notification__close p-0 d-flex" onClick={closeNotification}>
                  <i className="le-icon-x"></i>
                </button>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Notification;
