import React, { useEffect, useState } from "react"
import './ProductFavoriteButton.less'
import { AdobeAnalyticsUtil } from '/Foundation/Common/code/Views/Foundation/Common/AdobeAnalytics/AdobeAnalyticsUtil';

const ProductFavoriteButton = ({ productId, favoriteIds }) => {

    const [isFavorite, setIsFavorite] = useState(false);
    useEffect(() => {
        if (favoriteIds?.find(x => x === productId))
            setIsFavorite(true);
    }, []);

    const favorites = (() => {
        return {
            getCookie: (name) => {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
                return null;
            },
            setCookie: (name, value, days) => {
                let expires = "";
                if (days) {
                    const date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toUTCString();
                }
                document.cookie = name + "=" + (value || "") + expires + "; path=/";
            },
        }
    })();

    const toggleFavorite = async () => {
        const response = await fetch('/api/sitecore/productfavorites/ToggleProductFavorite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productId }),
        });

        if (!response.ok)
            throw new Error(`Error: ${response.status} - ${response.statusText}`);

        const data = await response.json();
        if (data) {
            favorites.setCookie('ProductFavorites', JSON.stringify(data), 7);
            const isfav = data?.find(x => x === productId) ? true : false;
            setIsFavorite(isfav);
            AdobeAnalyticsUtil.DataLayer.TrackEvent('favorite_product ', isfav ? "Like" : "Unlike");
        }
    }

    return <button onClick={toggleFavorite} className="btn p-0 productFavoriteButton"><i className={`le-icon-${isFavorite ? 'heart-fill' : 'heart'}`}></i><span className="sr-only">Toggle favorite</span></button>

}

export default ProductFavoriteButton;