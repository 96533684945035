export const fieldIsEmpty = (fieldValue) => {
  if (!fieldValue) return true;
  return false;
};

export const emailHasErrors = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/.test(email)) {
    return false;
  }
  return true;
};

export const textHasErrors = (text) => {
  if (!text || text.length < 2) {
    return true;
  }
  return false;
};

export const addressHasErrors = (str) => {
  if (/^(P[O|0]\s\#|BOX\s\#|(P[\.|\s]?[O|0][\s|\.|s].*\bBOX))/gi.test(str)) {
    return true;
  }
  return false;
};

const phoneNumberRegexByMarkets = {
  DK: /^\+?(\d[\-\s]?){10,15}$/,
  SE: /^\+?(\d[\-\s]?){9,15}$/,
  DE: /^\+?(\d[\-\s]?){6,20}$/,
  PL: /^\+?(\d[\-\s]?){9,15}$/,
  PT: /^\+?(\d[\-\s]?){9,15}$/,
  NL: /^\+?(\d[\-\s]?){9,15}$/,
  CZ: /^\+?(\d[\-\s]?){9,15}$/,
  FR: /^\+?(\d[\-\s]?){9,15}$/,
  GB: /^\+?(\d[\-\s]?){9,15}$/,
  FI: /^\+?(\d[\-\s]?){9,20}$/,
  US: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  CA: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  ES: /^\+?(\d[\-\s]?){9,15}$/,
  BE: /^\+?(\d[\-\s]?){11,15}$/,
  IT: /^\+?(\d[\-\s]?){9,15}$/,
  AT: /^\+?(\d[\-\s]?){7,20}$/,
  NO: /^\+?(\d[\-\s]?){7,15}$/,
  IE: /^\+?(\d[\-\s]?){8,16}$/,
  CH: /^\+?(\d[\-\s]?){10,14}$/,
};

export const phoneHasErrors = (nr, countryCode) => {
  if (!countryCode && _THULEDATA?.SitecoreLanguage) countryCode = _THULEDATA?.SitecoreLanguage?.split('-')[1];

  if (!countryCode) return true;

  const validationSettings = phoneNumberRegexByMarkets[countryCode];
  return !validationSettings || !validationSettings.test(nr);
};

export const isntThreeOrFourDigits = (str) => {
  if (/^[0-9]{3,4}$/.test(str)) {
    return false;
  }
  return true;
};

export const dateHasErrors = (date) => {
  let [month, year] = date.split('/');

  if (!month || !year) return true;

  year = parseInt(`20${year}`, 10);

  const now = new Date();
  const thisYear = now.getFullYear();

  if (year < thisYear || year > thisYear + 20) {
    return true;
  }
  const thisMonth = now.getMonth() + 1;
  if (year == thisYear && month < thisMonth) {
    return true;
  }
  return false;
};

export const cardNumberHasErrors = (str) => {
  const trimmed = str.replace(/\D+/g, '');
  if (trimmed.length < 13 || trimmed.length > 19) {
    return true;
  }
  if (!luhnChk(trimmed)) {
    return true;
  }
};

export const luhnChk = (function (arr) {
  return function (ccNum) {
    let len = ccNum.length,
      bit = 1,
      sum = 0,
      val;

    while (len) {
      val = parseInt(ccNum.charAt(--len), 10);
      sum += (bit ^= 1) ? arr[val] : val;
    }

    return sum && sum % 10 === 0;
  };
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);
