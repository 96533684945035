import React from 'react';
import ProductFavoriteButton from '/Foundation/React/code/Components/ProductFavoriteButton';
import { createRoot } from 'react-dom/client';
window.Isotope = require('isotope-layout/dist/isotope.pkgd.min.js');

export const ProductFavorites = (() => {
    return {
        init: () => {
            if (!_THULEDATA.isB2CEnabled || !_THULEDATA.enableFavorites)
                return;

            fetch('/api/sitecore/ProductFavorites/GetProductFavorites')
                .then((response) => response.json())
                .then((data) => {
                    const productFavoriteButtons = document.querySelectorAll(".productFavoriteButtonContainer");
                    for (const favoriteButton of productFavoriteButtons) {

                        const pimId = favoriteButton.closest('[data-pimid]')?.getAttribute("data-pimid");
                        if (pimId) {
                            const root = createRoot(favoriteButton);
                            root.render(<ProductFavoriteButton productId={pimId} favoriteIds={data} />);
                        }

                        const nonSolutionButton = document.querySelector("#productBuyArea .product-add-to-cart > button.rpa-pdp-add-to-cart");
                        if (nonSolutionButton)
                            favoriteButton.classList.remove("d-none");
                    }
                    ProductFavoritesList.init();
                });
        }
    }
})();

const ProductFavoritesList = (() => {
    let isotope = undefined;
    let self = {
        init: () => {
            const container = document.querySelector('.isotope-container');
            if (!container)
                return;
            isotope = new Isotope(container, {
                itemSelector: '.isotope-item',
                layoutMode: 'fitRows',
                fitRows: {
                    gutter: '.gutter-sizer',
                },
                resizable: false,
            });
        },
    };
    return self;
})();