import React from 'react';
import './Checkbox.less';

const Checkbox = ({
  id,
  label,
  secondaryLabel = '',
  checked,
  name,
  onChange,
  disabled = false,
  supportingText = '',
  cssClasses = '',
  errorMessage = '',
  inputClassName = '',
  link = '',
  linkText = '',
}) => {
  const handleClick = () => {
    if (disabled) return;
    onChange();
  };

  const handleInputChange = (e) => {
    if (e.keyCode === 32 && !disabled) {
      onChange();
    }
  };

  return (
    <div disabled={disabled} className={`thule-checkbox ${cssClasses || ''}`}>
      <input
        onKeyDown={handleInputChange}
        id={id}
        type="checkbox"
        checked={checked}
        name={name}
        onChange={() => {}}
        className={inputClassName}
      />
      <label htmlFor={id} onClick={handleClick}>
        <i className="le-icon-check-small"></i>
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
        {secondaryLabel && <span className="secondary-label" dangerouslySetInnerHTML={{ __html: secondaryLabel }}></span>}
        {link && (
          <a className="px-2" href={link} target="_blank">
            {linkText}
          </a>
        )}
      </label>
      {supportingText && <div className="supporting-text paragraph--xs">{supportingText}</div>}
      {errorMessage && <div className="error-message mt-3">{errorMessage}</div>}
    </div>
  );
};

export default Checkbox;
